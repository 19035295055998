import { AreaPageListing, SortBy } from '@zoocasa/go-search';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import { Breadcrumb, generateBreadcrumbs } from 'components/breadcrumbs';
import buildHeadTags from 'components/dynamic-page/area-listings-page/build-head-tags';
import { AreaListingsRouteMatchObject, AREA_LISTINGS_ROUTE, CITY_LOCATION_TYPE, generateRouteMatchObjectFromPath, PROVINCE_LOCATION_TYPE, RouteMatchObject } from 'components/dynamic-page/route-matchers';
import isUserAgentCrawler, { isUserAgentLighthouse } from 'utils/crawler-agent';
import extractPageFromUrl, { DEFAULT_PAGE_NUMBER } from 'utils/extract-page-from-url';
import { getSlugFallbackFromURLSearchParams, getSortFromURLSearchParams } from 'utils/listing-query-helper';
import generateCityFooterData, { generateNeighbourhoodFooterData } from '../area-listings-page/generate-dynamic-footer-data';
import generateHeading from './generate-heading';
import { sortBy } from 'data/listing';
import Address, { getAddressesBySlug, toJson } from 'data/addresses';
import sortAddresses from 'utils/sort-addresses';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import configJSON from 'config.json';
import { CountryCode, CountryCodeList } from 'types/countries';
import { getThemeOverrideFromRequest, themeNameOrDefault } from 'utils/themes';
import { themes } from 'themes';
import { getNamedContents } from 'data/named-content';
import { getSiteLocationFromRequest } from 'utils/site-location';
import { AREA_PAGE_CONSTANTS } from 'constants/pagination';
import { SearchApi, SearchApiType } from 'data/search/api';
import { getGoSearchHost } from 'utils/host-config';
import { SearchByAreaFilterType } from 'data/search/area/types';

import type { Filter, ListingParams, Sort } from 'contexts/preferences/listing-params/types';
import type TNamedContent from 'data/named-content';
import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import type { DynamicPageServerSideProps } from 'types/dynamic_page_types';
import type { LinkDataType } from 'components/home-page/internal-links';
import type { FiltersType, Pagination } from './area_listings_page_view_model';
import type { PartialDeep } from 'type-fest';

//#region Types
export interface AreaListingsPageServerSideProps {
  viewModel: {
    addresses: Address[];
    breadcrumbs: Breadcrumb[];
    listingsData: unknown[];
    filter: FiltersType;
    heading: string;
    pagination: Pagination;
    genericNamedContent: string[] | null;
    areaBlurb: string | null;
    areaBlurbHeading: string | null;
    isCrawler: boolean;
    isCrawlerLighthouse: boolean;
    footerData: LinkDataType[];
    city: string;
    province: string;
    neighbourhood: string;
    sort: Sort;
    fallbackAddress: Address | null;
  };
}

type GetServerSidePropsReturnType = Promise<GetServerSidePropsResult<DynamicPageServerSideProps<AreaListingsPageServerSideProps>>>;

//#endregion

//#region Constants
export const CACHE_CONTROL_HEADER_NAME = 'Cache-Control';
export const CACHE_CONTROL_HEADER_VALUE = 'public, s-maxage=1800, stale-while-revalidate=60';

const CANADA_ADDRESS = { id: 1, addressType: 'country', country: 'canada', province: '', slug: 'ca', label: 'Canada' };
const US_ADDRESS = { id: 6120961, addressType: 'country', country: 'usa', province: '', slug: 'us', label: 'Usa' };
//#endregion

/**
 * When useUsListings feature flag is off, we force all US searches to fallback to Canada instead.
 */
export async function fetchAreaPageListingsWithCanadaFallback(country: string, slug: string, sort: Sort, filter: SearchByAreaFilterType, pageNumber: number, pageSize: number, searchApi: SearchApiType) {
  return {
    addresses: [],
    address: CANADA_ADDRESS,
    areaPageListingResponse: await searchApi.searchByArea(CountryCodeList.CANADA, '', '', '', filter, getSortBy(sort), pageNumber, pageSize),
    isExpandedArea: true,
  };
}

export async function fetchAreaPageListingsWithFallback(country: string, slug: string, sort: Sort, filter: SearchByAreaFilterType, pageNumber: number, pageSize: number, searchApi: SearchApiType) {
  const addresses = slug ? await getAddressesBySlug(slug) : [];
  if (addresses && addresses?.length > 0) {
    const sortedAddresses: Address[] = sortAddresses(addresses); 
    for (let index = sortedAddresses.length - 1; index >= 0; index--) {
      const address = sortedAddresses[index];
      const provinceOrState = SanitizeProvinceOrStateCode(address.provinceCode ?? '');
      const city = SanitizeName(address.subDivision ?? '');
      const neighbourhood = address.neighbourhood ?? '';
      const areaPageListingResponse = await searchApi.searchByArea(country, provinceOrState, city, neighbourhood, filter, getSortBy(sort), pageNumber, pageSize);

      if (areaPageListingResponse.TotalCount > 0) {
        return { addresses, address, areaPageListingResponse, isExpandedArea: false };
      }
    }
  }
  // We couldn't find the address based on the fallback slug. Default to top most level ("country")
  return {
    addresses,
    address: country === CountryCodeList.UNITED_STATES ? US_ADDRESS : CANADA_ADDRESS,
    areaPageListingResponse: await searchApi.searchByArea(country, '', '', '', filter, getSortBy(sort), pageNumber, pageSize),
    isExpandedArea: true,
  };
}

export function grabSlug(slug: string, slugFallback: string | null) {
  let areaPageSlug = (slugFallback && slugFallback?.length > 0) ? slugFallback : slug;
  if (areaPageSlug.includes('/')) {
    areaPageSlug = areaPageSlug.replace(/^(.*?)\/(.*)$/, '$2-$1');
  }
  return areaPageSlug;
}

export default async function getServerSideProps(context: GetServerSidePropsContext): GetServerSidePropsReturnType {
  const featureOverrides = getFeaturesOverrideFromRequest(context.req);
  const features = { ...configJSON.features, ...featureOverrides };
  const themeName = getThemeOverrideFromRequest(context.req);
  const theme = themes[themeNameOrDefault(themeName)];
  const isZoocasa = themeName === 'zoocasa';
  /**
   * This value is considered fresh for 30 minutes (s-maxage=1800).
   * If a request is repeated within the next 30 minutes, the previously cached
   * value will still be fresh. If the request is repeated before 60 seconds,
   * the cached value will be stale but still render (stale-while-revalidate=60).
   */
  context.res.setHeader(CACHE_CONTROL_HEADER_NAME, CACHE_CONTROL_HEADER_VALUE);

  const { params, path, routeMatchObject, slugFallback } = extractUrlData(context);
  const { sort, page: { number: pageNumber, size: pageSize }, filter } = params;
  const { slug = '' } = filter;
  const routeMatchLocationType = routeMatchObject.locationType || routeMatchObject.city && CITY_LOCATION_TYPE || routeMatchObject.province && PROVINCE_LOCATION_TYPE;

  const provinceOrState: string = SanitizeProvinceOrStateCode(routeMatchObject.provinceCode);
  const provinceOrStateName: string = SanitizeName(routeMatchObject.province);
  const city: string = SanitizeName(routeMatchObject.city);
  const neighbourhood: string = SanitizeName(routeMatchObject.neighbourhood);
  let fallbackAddress:Address | null = null;
  
  // If the site country does NOT match the listing country, fall back to site country, unless we're on zoocasa tenant
  const siteCountry = getSiteLocationFromRequest(context.req);
  const listingCountry = routeMatchObject.country?.toUpperCase() as CountryCode || countryCodeFromProvinceOrState(provinceOrState);
  
  const country = (siteCountry === listingCountry || isZoocasa) ? listingCountry : siteCountry;
    
  /**
   * When a fallback slug is present we use it as the source of fetchAreaPageListings instead of the original slug.
   *
   * Having the fallback slug indicates we have to fallback to a different address level in order to find relevant
   * listing as the original area didn't return any listing for the given filter.
   */
  const areaPageSlug = grabSlug(slug, slugFallback);
  const areaFilter: SearchByAreaFilterType = { ...filter, hasImage: false };
  const searchApi = SearchApi.create(getGoSearchHost(true), features.useLegacySearchFilter || false);

  const useUsListings = features.useUsListings || false;
  const useCanadaFallbackOverride = !useUsListings && country === 'US';

  const { addresses, address, areaPageListingResponse } = useCanadaFallbackOverride ? 
    await fetchAreaPageListingsWithCanadaFallback(country, areaPageSlug, sort, areaFilter, pageNumber, pageSize, searchApi) : 
    await fetchAreaPageListingsWithFallback(country, areaPageSlug, sort, areaFilter, pageNumber, pageSize, searchApi);

  const { TotalPages: total, TotalCount: count, PageNumber: page, PageSize: size, data: listings } = areaPageListingResponse;
  if (address.slug !== slug && routeMatchLocationType) {
    fallbackAddress = address as Address;
  }
  // FIXME: add this to the go-search response
  const listingsSortedByPrice = sortBy(listings, (listing: AreaPageListing) => listing?.price, 'ascending');
  const lowestPrice = listingsSortedByPrice?.[0]?.price;
  const highestPrice = listingsSortedByPrice?.[listingsSortedByPrice.length -1 ]?.price;

  const heading = generateHeading (params, routeMatchObject, fallbackAddress, features.useLegacySearchFilter || false);
  const breadcrumbs = generateBreadcrumbs(country, addresses);
  const isCrawler = isUserAgentCrawler(context.req.headers['user-agent']);
  const isCrawlerLighthouse = isUserAgentLighthouse(context.req.headers['user-agent']);
  let footerData: LinkDataType[] = [];
  if (isCrawler) {
    footerData = await getFooterData({ ...routeMatchObject, locationType: routeMatchLocationType }, filter.latitude, filter.longitude, features.useLegacySearchFilter || false) || [];
  }

  let genericNamedContent: string[] | null = null;
  let areaBlurb: string | null = null;
  let areaBlurbHeading: string | null = null;
  if (count > 0) {
    const genericNamedContents = await getAreaNamedContents(routeMatchObject);
    if (genericNamedContents.length > 0) {
      const namedContent = genericNamedContents?.[0];
      const hasAreaBlurb = namedContent.content.includes('DESCRIPTION BREAK');
      const splitNamedContentData = hasAreaBlurb ? namedContent.content.split('<p>DESCRIPTION BREAK</p>') : namedContent.content;
      areaBlurb = hasAreaBlurb ? splitNamedContentData[0] : null;
      genericNamedContent = hasAreaBlurb ? splitNamedContentData[1]?.split('<p>SECTION BREAK</p>') : (splitNamedContentData as string).split('<p>SECTION BREAK</p>');
      areaBlurbHeading = hasAreaBlurb ? generateAreaBlurbHeading(city, neighbourhood, provinceOrStateName) : null;
    }
  }
  const viewModel: AreaListingsPageServerSideProps['viewModel'] = {
    addresses: addresses.map(a => toJson(a)),
    breadcrumbs,
    listingsData: listings.map((listing: AreaPageListing) => AreaPageListing.toJSON(listing)),
    filter: params.filter,
    heading,
    pagination: {
      page,
      size,
      count: Math.min(AREA_PAGE_CONSTANTS.MAX_TOTAL_COUNT, count),
      total: Math.min(AREA_PAGE_CONSTANTS.MAX_PAGE_COUNT, total),
    },
    genericNamedContent: genericNamedContent || null,
    areaBlurb,
    areaBlurbHeading,
    isCrawler,
    isCrawlerLighthouse,
    footerData,
    city,
    province: provinceOrState,
    neighbourhood,
    sort,
    fallbackAddress: toJson(fallbackAddress),
  };

  const headPathName = path.replace(/^\/+/g, '').replace(/\/filter.*$/g, '');
  return {
    props: {
      routeName: AREA_LISTINGS_ROUTE,
      props: {
        headTags: buildHeadTags(theme, headPathName, count, lowestPrice, highestPrice, breadcrumbs, listings, params),
        viewModel,
      },
    },
  };
}

//#region Utility Functions

export async function getAreaNamedContents(routeMatchObject: AreaListingsRouteMatchObject, isSpecific = true, hasReran = false): Promise<TNamedContent[]> {
  const isSpecificHomeType = routeMatchObject.propertyType !== 'real-estate';
  const key = genericNamedContentKey(routeMatchObject, isSpecific);
  const genericNamedContents = await getNamedContents({ filter: { key }});
  if (!hasReran && isSpecificHomeType && genericNamedContents.length === 0) {
    return getAreaNamedContents(routeMatchObject, false, true);
  }
  return genericNamedContents;
}

export async function getFooterData(params: RouteMatchObject, lat: number, long: number, useLegacySearchFilter = false) {
  if (params.locationType === 'city') {
    const slug = params.filter.slug;
    const city = capitalizeWords(deDasherize(params.city));
    const province = params.provinceCode?.toUpperCase();
    const data = await generateCityFooterData(city, slug, province, 10, useLegacySearchFilter);
    return data;
  } else if (params.locationType === 'neighbourhood') {
    const city = capitalizeWords(deDasherize(params.city));
    const city_slug = params.cityWithProvinceCode;
    const neighbourhood_slug = params.filter.slug;
    const neighbourhood = capitalizeWords(deDasherize(params.neighbourhood));
    const province = params.provinceCode?.toUpperCase();
    const data = await generateNeighbourhoodFooterData(city, city_slug, neighbourhood, neighbourhood_slug, province, lat, long);
    return data;
  }
}

function genericNamedContentKey(routeMatchObject: RouteMatchObject, isSpecific = true) {
  const homeType = isSpecific && routeMatchObject.homeType !== 'real-estate' ? `-${routeMatchObject.homeType}` : '';
  const slug = routeMatchObject.filter.slug;
  const suffix = slug ? `-${slug}${homeType}` : '';
  return 'locations' + suffix;
}

export function extractUrlData(context: GetServerSidePropsContext) {
  const { query, resolvedUrl } = context;
  const routeMatchObject: AreaListingsRouteMatchObject = generateRouteMatchObjectFromPath(resolvedUrl, false);
  const filterFromRouteMatchObject: PartialDeep<Filter> = routeMatchObject.filter;
  const url = new URL(`https://example.com${resolvedUrl}`);
  const urlSearchParams: URLSearchParams = new URLSearchParams(url.search);
  const params: Required<ListingParams> = {
    sort: getSortFromURLSearchParams(urlSearchParams),
    page: {
      number: Math.max(extractPageFromUrl(query), DEFAULT_PAGE_NUMBER),
      size: AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE,
    },
    filter: { ... filterFromRouteMatchObject },
  } as Required<ListingParams>;
  const slugFallback = getSlugFallbackFromURLSearchParams(urlSearchParams);
  return { params, path: resolvedUrl, routeMatchObject, slugFallback };
}

export function getSortBy(sort: Sort): SortBy {
  switch (sort) {
  case '-price':
    return SortBy.PriceDesc;
  case 'price':
    return SortBy.PriceAsc;
  case '-date':
    return SortBy.DateDesc;
  case 'date':
    return SortBy.DateAsd;
  case '-bedrooms':
    return SortBy.BedroomsDesc;
  case 'bedrooms':
    return SortBy.BedroomsAsc;
  case '-bathrooms':
    return SortBy.BathroomsDesc;
  case 'bathrooms':
    return SortBy.BathroomsAsc;
  }
}

export function SanitizeName(name = '') {
  if (name?.length > 0) {
    return capitalizeWords(deDasherize(name));
  }
  return '';
}

export function SanitizeProvinceOrStateCode(provinceOrStateCode = '') {
  if (provinceOrStateCode?.length > 0) {
    return provinceOrStateCode.toUpperCase();
  }
  return '';
}

export function generateAreaBlurbHeading(city = '', neighbourhood = '', provinceOrState = '') {
  if (neighbourhood?.length > 0) {
    return `About ${neighbourhood}, ${provinceOrState}`;
  }
  if (city?.length > 0) {
    return `About ${city}, ${provinceOrState}`;
  }
  if (provinceOrState?.length > 0) {
    return `About ${provinceOrState}`;
  }
  return '';
}
//#endregion