import { COMMERCIAL_TARGETED_URL_PROPERTY_TYPE, 
  CONDO_TARGETED_URL_PROPERTY_TYPE, 
  FARMS_TARGETED_URL_PROPERTY_TYPE, 
  HOMES_TARGETED_URL_PROPERTY_TYPE, 
  HOUSES_TARGETED_URL_PROPERTY_TYPE, 
  LAND_TARGETED_URL_PROPERTY_TYPE, 
  REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE, 
  TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE,
  getTargetedUrlPropertyTypeSelection, 
  isForSaleOrRent,
} from 'utils/listing-query-helper';
import { areaName } from 'components/dynamic-page/area-listings-page/build-head-tags';
import { deDasherize } from '@zoocasa/node-kit/strings/de-dasherize';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import { CountryCodeList } from 'types/countries';

import { AVAILABLE_STATUS, NOT_AVAILABLE_SOLD_STATUS, type ListingParams } from 'contexts/preferences/listing-params/types';
import type { RouteMatchObject } from 'components/dynamic-page/route-matchers';
import type Address from 'data/addresses';

function areaNameWithCityProvince(routeMatchObject: RouteMatchObject) {
  let areaNameWithCityProvince = areaName(routeMatchObject);
  const { city, locationType, province, provinceCode } = routeMatchObject;
  if (areaNameWithCityProvince && city && locationType === 'neighbourhood') {
    areaNameWithCityProvince += `, ${deDasherize(city)}`;
  }
  if (province && locationType !== 'province') {
    areaNameWithCityProvince += `, ${deDasherize(province)}`;
  }
  if (provinceCode && locationType === 'province') {
    const countryName = CountryCodeList.CANADA === countryCodeFromProvinceOrState(provinceCode) ? 'Canada' : 'USA';
    areaNameWithCityProvince += `, ${countryName}`;
  }
  return areaNameWithCityProvince;
}

export default function generateHeading(listingParams: ListingParams, routeMatchObject: RouteMatchObject, fallbackAddress: Address | null, useLegacySearchFilter: boolean = false) {
  const filter = listingParams.filter;
  const listingsArea = areaNameWithCityProvince(routeMatchObject);

  const forSaleOrRent = isForSaleOrRent(listingParams.filter.rental);
  const homeTypeSelection = getTargetedUrlPropertyTypeSelection(routeMatchObject.filter.homeType);
  const house = filter.homeType.house;
  const townhouse = filter.homeType.townhouse;
  const condo = filter.homeType.condo;
  const farm = filter.homeType.farm;
  const commercial = filter.homeType.commercial;
  const land = filter.homeType.land;
  let homeType = homeTypeSelection === HOMES_TARGETED_URL_PROPERTY_TYPE ? 'Real Estate' : homeTypeSelection;

  if (useLegacySearchFilter) {
    if (house && !townhouse && !condo) {
      homeType = HOUSES_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && townhouse && !condo) {
      homeType = TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && !townhouse && condo) {
      homeType = CONDO_TARGETED_URL_PROPERTY_TYPE;
    } else {
      homeType = REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE;
    }
  } else {
    if (house && !townhouse && !condo && !farm && !commercial && !land) {
      homeType = HOUSES_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && townhouse && !condo && !farm && !commercial && !land) {
      homeType = TOWNHOUSE_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && !townhouse && condo && !farm && !commercial && !land) {
      homeType = CONDO_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && !townhouse && !condo && farm && !commercial && !land) {
      homeType = FARMS_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && !townhouse && !condo && !farm && commercial && !land) {
      homeType = COMMERCIAL_TARGETED_URL_PROPERTY_TYPE;
    } else if (!house && !townhouse && !condo && !farm && !commercial && land) {
      homeType = LAND_TARGETED_URL_PROPERTY_TYPE;
    } else {
      homeType = REAL_ESTATE_TARGETED_URL_PROPERTY_TYPE;
    }
  }



  if (filter.status !== AVAILABLE_STATUS) {
    let type = filter.status === NOT_AVAILABLE_SOLD_STATUS ? 'Sold' : '';
    let noListingsMessage = '';
    if (filter.rental) {
      type = 'Rental';
    }
    if (fallbackAddress) {
      noListingsMessage = ' / 0 Listings Found';
    }
    if (type == '') {
      return capitalizeWords(`${listingsArea} Past ${type} Listings${noListingsMessage}`);
    } else {
      return capitalizeWords(`${listingsArea} Recently ${filter.rental ? 'Rented' : 'Sold'} ${homeType}${noListingsMessage}`);
    }
  } else {
    let message: string;
    if (routeMatchObject.locationType === 'street') {
      message = `${homeType} ${forSaleOrRent} on ${listingsArea}`;
    } else if (listingsArea) {
      message = `${homeType} ${forSaleOrRent} in ${listingsArea}`;
    } else {
      message = `${homeType} ${forSaleOrRent}`;
    }

    if (fallbackAddress) {
      message += ' / 0 Listings Found';
    }
    return capitalizeWords(message);
  }
}
