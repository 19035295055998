import { camelize } from '@zoocasa/node-kit/strings/camelize';

import type Address from 'data/addresses';

export default function sortAddresses(addresses: Address[], validate = true) {
  return ['province', 'sub-division', 'place-name', 'neighbourhood', 'address'].map(locationType => {
    return addresses.find(address => {
      const addressProperty = camelize(address.addressType) as 'country' | 'province' | 'neighbourhood';
      let isAddressAreaValueValid = true;
      if (validate) {
        isAddressAreaValueValid = !/^\d+$/.test(address[addressProperty]!);
      }
      return address.addressType === locationType && isAddressAreaValueValid;
    });
  }).filter(address => address) as Address[];
}
