import React from 'react';
import ArrowIcon from 'components/icon/arrow-icon';
import sortAddresses from 'utils/sort-addresses';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { capitalizeWords } from '@zoocasa/node-kit/strings/capitalize';
import { CountryCode, CountryCodeList } from 'types/countries';
import getTargetedUrl from 'components/dynamic-page/new-area-listings-page/targeted-url-helper';
import { usePreferencesContext, useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

import type Address from 'data/addresses';
import { ProvinceOrState, provinceOrStateCodeFromName } from 'utils/province_or_state';

interface Props {
  breadcrumbs: readonly Breadcrumb[];
}

export interface Breadcrumb {
  name: string;
  link: string;
  isNotAreaPage: boolean;
}

export default function Breadcrumbs({ breadcrumbs }: Props) {
  const { originUrl } = useThemeContext();
  const host = !originUrl?.startsWith('http') ? originUrl?.startsWith('localhost') ? `http://${originUrl}` : `https://${originUrl}` : originUrl;
  const { listingParams } = usePreferencesContext();
  const { themeName } = useThemeContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;
  
  // Make sure the breadcrumb links have the correct filter parameters so that the user's filters persist
  const getBreadcrumbLinkWithFilters = (link: string, isNotAreaPage: boolean) => {
    // Listing parameters should only be added to area page urls, not address pages
    if (isNotAreaPage) {
      return link;
    }
    const fullUrlWithFilters = getTargetedUrl({ url: `${host}${link}`, filters: listingParams.filter, sort: listingParams.sort, pageNumber: 1 });
    return fullUrlWithFilters.href;
  };
  
  return (
    <div className={buildClassName(styles.component, isZoocasaTenant ? styles['zoocasa-theme'] : styles['exp-theme'])} data-testid={testIds.defaultBreadcrumbs}>
      <div className={styles['layout-container']}>
        {breadcrumbs.map((level: Breadcrumb, index: number) => {
          const lastElement = index < breadcrumbs.length - 1;
          const areaLabel = index > 1 ? capitalizeWords(level.name.toLowerCase()) : level.name.toUpperCase();
          return (
            <span key={index} className={styles.item}>
              <a href={level.link ? getBreadcrumbLinkWithFilters(level.link, level.isNotAreaPage) : ''} title={areaLabel} className="tertiary" data-testid={(level.name.replace(/[.,-\s]/g, '') + testIds.breadcrumbLink).toLowerCase()}>
                <span>{areaLabel}</span>
              </a>
              {lastElement && <ArrowIcon className={styles.arrow} />}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export const generateBreadcrumbs = (country: CountryCode, addresses: Address[], addressPath?: string): Breadcrumb[] => {
  const isAmericanLocation = country === CountryCodeList.UNITED_STATES;
  const countryBreadcrumb = { name: isAmericanLocation ? 'USA' : 'CA', link: `/${isAmericanLocation ? 'us' : 'ca'}-real-estate`, isNotAreaPage: false };
  const breadcrumbs = sortAddresses(addresses).map(({ label, generateLink, isNotAreaPage }, index) => {
    return {
      name: index === 0 ? provinceOrStateCodeFromName(label as ProvinceOrState) : label,
      link: generateLink(addressPath),
      isNotAreaPage: isNotAreaPage,
    };
  });
  return [countryBreadcrumb, ...breadcrumbs];
};
