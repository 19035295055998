
import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { HttpRequestMethodTypes } from 'types';
import endpoint from 'utils/endpoint';

export default class NamedContent {
  key: string;
  title: string | null;
  metaDescription: string | null;
  content: string | any;
  contentType: 'html' | 'json' | 'markdown'; // NOTE: The API is incorrecly returning "markdown" for html content

  constructor(namedContent: Record<string, unknown>) {
    const camelizedNamedContent = camelizeKeys(namedContent);
    const attributes = camelizedNamedContent.attributes as Record<string, unknown>;
    const relationships = camelizedNamedContent.relationships as Record<
      string,
      unknown
    >;
    const formattedNamedContent = {
      ...attributes,
      ...relationships,
    };
    Object.assign(this, formattedNamedContent);
  }
}

export async function getNamedContents(params: Record<string, unknown>) {
  const { data } = await endpoint<{data: Record<string, unknown>[]}>('https://cms.zoocasa.com/v1/named-contents', HttpRequestMethodTypes.GET, params);
  return data.map(d => new NamedContent(d));
}
